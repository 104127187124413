import { Alert, Box, CardContent, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import FileUploadInput from '../../../../components/FormFIelds/FIleUploadInput';
import { FullPageLoadingCircle } from '../../../../components/FullPageLoadingCircle/FullpageLoadingCircle';
import { ClientService } from '../../../../repository/ClientService';
import './client-mlro-conversation.scss';
import KycCard from '../../../../shared/components/KycCard/KycCard';
import MainPage from '../../../../shared/pages/main-page/MainPage';
import { KycButton } from '../../../../shared/styled-components/KycButton';

const ClientMlroConversation = () => {
  const [queryParameters] = useSearchParams();
  const token = queryParameters.get('token');
  const [newMessage, setNewMessage] = useState<string>('');
  const [showSinner, setShowSpinner] = useState<boolean>(false);
  const [successSendMessage, setSuccessSendMessage] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);
  const [isExpiredLink, setIsExpiredLink] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (token) {
      getMessage();
    }
  }, [token]);

  const getMessage = () => {
    ClientService.getMessage(token!).then((data) => {
      if (data.message) {
        setIsExpiredLink(true);
      }
    }).catch(() => {
      setIsExpiredLink(true);
    });
  };

  const handleCloseSpinner = () => {
    setShowSpinner(false);
  };

  const handleFileChange = (files: File[]) => {
    setFiles(files);
  };

  const getFileDto = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({
          name: file.name,
          mimeType: file.type,
          base64Content: reader.result
        });
    });
  };

  const initiateCommunication = (e: FormEvent) => {
    e.preventDefault();
    const filePromises = files.map((file: File) => getFileDto(file));
    Promise.all(filePromises).then((fileDtos) => {
      const data = {
        message: newMessage,
        token: token,
        files: fileDtos
      };
      setShowSpinner(true);
      ClientService.answerToMRLO(data).then(() => {
        setNewMessage('');
        setShowSpinner(false);
        setSuccessSendMessage(true);
      }).catch(() => setShowSpinner(false));
    });
  };

  return <MainPage>
        <KycCard className='message-card'>
          <CardContent>
          <Typography textAlign='center' color={'primary'} variant='h2' id='title'>
            {t('PROCEDURE_CONVERSATION')}
          </Typography>
          {!isExpiredLink ? <Box>
              {successSendMessage ?
                <Alert severity='success'>{t('MESSAGE_SUCCESSFULLY_SENT')}</Alert> :
                <form onSubmit={initiateCommunication}>
                  <Box paddingY={2}>
                    <TextField
                      id='procedureConversation'
                      label={t('ENTER_MESSAGE')}
                      multiline
                      fullWidth={true}
                      color='primary'
                      value={newMessage}
                      maxRows={8}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => setNewMessage(e.target.value)}>
                    </TextField>
                    <Box marginTop={3} marginBottom={2}>
                      <FileUploadInput
                        label={t('UPLOAD_FILES')}
                        selectedFiles={files}
                        onFileChange={handleFileChange}
                        index={0}
                        name='files'/>
                    </Box>
                    <Box textAlign='end' marginTop={3}>
                      <KycButton variant='contained'
                              color='secondary'
                              className='addButton'
                              type='submit'
                              disabled={newMessage.trim() === ''}>
                        {t('SEND')}
                      </KycButton>
                    </Box>
                  </Box>
                </form>
              }
              <FullPageLoadingCircle loading={showSinner} handleClose={handleCloseSpinner}/>
            </Box>
            : <Box className='expired-link-message'>
              <Alert severity={'warning'}>{t('MESSAGE_PROVIDED')}</Alert>
            </Box>
          }
          </CardContent>
        </KycCard>
  </MainPage>;
};
export default ClientMlroConversation;
