import { Box } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import './main-page.scss';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/Header';
import { isSafe2TransactMode } from '../../../config/theme/ThemeUtils';

function MainPage(props: PropsWithChildren) {
  return <Box className='main-page'>
    {!isSafe2TransactMode() && <Header/>}
    <Box className='main-page-content'>{props.children}</Box>
    <Footer/>
  </Box>;
}

export default MainPage;
