import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import './App.scss';
import { getThemeOptions } from './config/theme/ThemeUtils';
import RoutesComponents from './routes/RoutesComponents';
import './i18n';

const theme = createTheme(getThemeOptions());

function App() {

  const appTheme = process.env.REACT_APP_THEME;

  const link = document.createElement('link');
  link.rel = 'icon';
  const title = document.createElement('title');
  link.rel = 'icon';

  if (appTheme === 'onboarding') {
    title.text = 'Digiscan';
    link.href = '/assets/images/onboarding/logo.svg';
  } else {
    title.text = 'Safe2transact';
    link.href = '/assets/images/safe2transact/logo.svg';
  }

  document.head.appendChild(link);
  document.head.appendChild(title);

  return (
    <ThemeProvider theme={theme}>
      <RoutesComponents/>
    </ThemeProvider>
  );
}

export default App;
