import { Box, Checkbox, FormControlLabel, Grid, Radio, RadioGroup, SelectChangeEvent, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { KycButton } from '../../../shared/styled-components/KycButton';
import { ProcedureType } from '../constants/ProcedureType';
import { FileName } from '../enums/FileName';
import { TermsAndConditionsProps } from './TermsAndConditionsProps';
import './TermsAndConditions.scss';

const TermsAndConditions = (props: TermsAndConditionsProps) => {
  // indexes description for terms and conditions
  //   acceptedIsPep [3]
  //   acceptedBeneficiaries: [4],
  //   acceptedRepresentativeContact: [1],
  //   acceptedInfoThirdParty: [2],
  //   acceptedPolicy: [0],
  const [checkedState, setCheckedState] = useState([false, false, false, false]);
  const { t } = useTranslation();
  const [confirmedNotPep, setConfirmedNotPep] = useState<boolean | null>(null);

  const handleCheckboxChange = (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const updatedCheckedState = [...checkedState];
    updatedCheckedState[index] = event.target.checked;
    setCheckedState(updatedCheckedState);
    props.onCheckboxChange(index, event.target.checked);
  };

  const handleChangePepAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    const notPep = event.target.value === 'true';
    setConfirmedNotPep(notPep);
    const updatedCheckedState = [...checkedState];
    updatedCheckedState[3] = notPep;
    setCheckedState(updatedCheckedState);
    props.onCheckboxChange(3, notPep);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant='h6' className='step-subtitle'>
          {t('TERMS_AND_CONDITIONS')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container item xs={12} rowSpacing={2}>
          <Grid item xs={12} alignItems={'center'} display={'flex'}>
            <Checkbox required onChange={handleCheckboxChange(0)}
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 28 }
                      }}/>
            {t('CORRECT_PERSONAL_INFO')}
          </Grid>
          <Grid item xs={12} display={'flex'} alignItems={'center'}>
            <Checkbox required onChange={handleCheckboxChange(1)}
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 28 }
                      }}/>
            {t('S2T_ABLE_TO_CONTACT')}
          </Grid>
          <Grid item xs={12} display={'flex'} alignItems={'center'}>
            <Checkbox required onChange={handleCheckboxChange(2)}
                      sx={{
                        height: '100%',
                        '& .MuiSvgIcon-root': { fontSize: 28 }
                      }}/>
            <Box>
              {t('INFO_SEND_TO_THIRD_PART')} &nbsp;
              <Link
                to={`/view-pdf/${FileName.PRIVACY_POLICY}/view`}
                className={'link link-element privacy-link'} target={'_blank'}>
                Safe2Transact Privacy Policy</Link>,<Link
              to={`/view-pdf/${FileName.COOKIES_POLICY}/view`}
              className={'link link-element privacy-link'}
              target={'_blank'}> Cookie
              policy</Link> and <Link to={`/view-pdf/${FileName.TOKEN_PRE_SALE}/view`}
                                      className={'link link-element privacy-link'}
                                      target={'_blank'}>Token Sale
              policy.</Link>
            </Box>
          </Grid>
          {props.procedureType === ProcedureType.INDIVIDUAL &&
            <Grid item xs={12} alignItems={'center'} display={'flex'}>
              <Checkbox required onChange={handleCheckboxChange(4)}
                        sx={{
                          '& .MuiSvgIcon-root': { fontSize: 28 }
                        }}/>
              {t('BENEFICIARY_CONFIRMATION')}
            </Grid>
          }
          <Grid item xs={12} display={'flex'} alignItems={'center'}>
            <Grid container className='pep-confirm'>
              <Grid item xs={12}>
                <Box>
                  {t('PEP_CONFIRM')} ( <Link
                  to={`/view-pdf/${FileName.POLITICAL_EXPOSED_PERSON_INFO}/view`}
                  className={'link link-element privacy-link'}
                  target={'_blank'}>Political
                  Exposed Persons</Link> )
                </Box>
              </Grid>
              <Grid item xs={12}>
                <RadioGroup row
                            value={confirmedNotPep}
                            onChange={handleChangePepAnswer}
                            name='radio-buttons-group'>
                  <FormControlLabel value={true} control={<Radio/>} label={t('PEP_ANSWER_YES')}/>
                  <FormControlLabel value={false} control={<Radio/>} label={t('PEP_ANSWER_NO')}/>
                </RadioGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ mt: 1 }} display='flex' justifyContent='center'>
        <KycButton
          disabled={!(checkedState[0] && checkedState[1] && checkedState[2]) || confirmedNotPep === null}
          variant='contained'
          onClick={props.submitVerification}
          className='basic-button'>
          {t('SUBMIT_VERIFICATION')}
        </KycButton>
      </Grid>
    </>
  );
};
export default TermsAndConditions;
