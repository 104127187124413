import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';
import { isSafe2TransactMode } from '../../config/theme/ThemeUtils';

export const KycSelectInputLabel = styled(InputLabel)<InputLabelProps>(() => ({
    '&[data-shrink="true"]': {
      top: isSafe2TransactMode() ? '0' : '12px'
    }
  })
);
