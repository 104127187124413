import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getLogoUrl } from '../../../config/theme/ThemeUtils';

export const KycBoxStyled = styled(Box)<BoxProps>(({ theme }) => ({
  position: 'relative',
  padding: '1rem',
  '&::before': {
    content: `""`,
    display: 'block',
    width: '6rem',
    height: '6rem',
    position: 'absolute',
    top:"-2rem",
    left: 'calc(50% - 55px)',
    backgroundImage: `url(${getLogoUrl()})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
}));

