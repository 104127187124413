import { Card, CardProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { KycBoxStyled } from '../KycBox/KycBox';

const KycCardStyled = styled(Card)<CardProps>(({ theme }) => ({
  borderRadius: '30px',
  boxShadow: '0px 0px 20px 0px #0000001A',
  '.MuiCardActions-root': {
    padding: 'unset',
    paddingTop: '1rem'
  },
  '.MuiCardContent-root': {
    padding: '3rem'
  },
  [theme.breakpoints.down('md')]: {
    border: 0,
    boxShadow: 'unset',
    '.MuiCardContent-root': {
      padding: '1rem',
      paddingTop: '3rem'
    }
  }
}));

function KycCard(props: CardProps) {
  return <KycBoxStyled>
    <KycCardStyled {...props}>{props.children}</KycCardStyled>
  </KycBoxStyled>;

}

export default KycCard;
