import { ThemeOptions } from '@mui/material/styles/createTheme';

declare module '@mui/material/styles' {
  interface Palette {
    cancel: Palette['primary'];
    dashboard: Palette['primary'];
    gold: Palette['primary'];
  }

  interface PaletteOptions {
    cancel?: PaletteOptions['primary'];
    dashboard?: PaletteOptions['primary'];
    gold: Palette['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    cancel: true;
    dashboard: true;
    gold: true;
  }
}

export const safe2TransactThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#5B2483',
      light: '#702ca0',
      dark: '#461b64',
      contrastText: '#fff'
    },
    secondary: {
      main: '#F6A000',
      light: '#F7B332',
      dark: '#DD9000',
      contrastText: '#fff'
    },
    text: {
      primary: '#010101'
    },
    error: {
      main: '#d32f2f',
      light: '#ef5350',
      dark: '#c62828'
    },
    warning: {
      main: '#ed6c02',
      light: '#ff9800',
      dark: '#e65100'
    },
    info: {
      main: '#0288d1',
      light: '#03a9f4',
      dark: '#01579b'
    },
    success: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20'
    },
    cancel: {
      main: '#818589',
      light: '#818589',
      dark: '#71797E',
      contrastText: '#fff'
    },
    dashboard: {
      main: '#fff',
      light: '#818589',
      dark: '#a18dbf',
      contrastText: '#5B2483'
    },
    gold: {
      main: '#AEA18A',
      light: '#AEA18A',
      dark: '#AEA18A',
      contrastText: '#fff'
    }
  },
  typography: {
    fontSize: 16,
    fontFamily: 'Montserrat, sans-serif',
    fontWeightMedium: 400,
    h6: {
      fontSize: '1.25rem'
    },
    h5: {
      fontSize: '1.5rem'
    },
    h4: {
      fontSize: '2rem'
    },
    h3: {
      fontSize: '2.5rem'
    },
    h2: {
      fontSize: '3rem'
    },
    h1: {
      fontSize: '4rem'
    },
    body1: {
      fontSize: '1rem'
    },
    body2: {
      fontSize: '0.875rem'
    },
    caption: {
      fontSize: '0.75rem',
      color: '#A4ABB3'
    },
    button: {
      textTransform: 'none'
    }
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        disableRipple: true
      },
      styleOverrides: {
        root: {
          borderRadius: '10px',
          padding: '0.5rem 1rem'
        }
      }
    }
  }
};
