import { ThemeOptions } from '@mui/material';
import { EnvironmentMode } from './EnvironmentMode';
import { kycThemeOptions } from './KycTheme';
import { safe2TransactThemeOptions } from './Safe2TransactTheme';

export function getThemeOptions(): ThemeOptions {
    return  process.env.REACT_APP_THEME === 'onboarding'
        ? kycThemeOptions
        : safe2TransactThemeOptions;
}

export function isSafe2TransactMode() {
    return process.env.REACT_APP_THEME === 'safe2transact';
}

export function getLogoUrl(): string {
    return `${process.env.REACT_APP_IMAGES_LOCATION}/logo.svg`;
}


export function getFaviconPath(mode: string): string {
    return mode === EnvironmentMode.ONBOARDING
        ? '/images/mk/favicon.png'
        : '/images/xk/favicon.ico';
}
